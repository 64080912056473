import React from "react"
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

const Services = () => {
  return (
    <Layout>
      <SEO
        title="Oxygen Real Estate Digital Services"
        description="Oxygen has digital automation tools for the Real Estate industry!"
      />
      <Navbar />
      <div className="services-hero-banner">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8">
              <div className="main-banner-content">
                <h1>Oxygen Real Estate<br/>Digital Services​</h1>
              </div>
            </div>
          </div>
          <div className="page-description">
            <h2>Oxygen has digital automation tools for the Real Estate industry! </h2>
            <p>
              We have state of the art, automated emails and SMS’s that trigger when customers enquire about property listings on leading portals such as Realestate.com.au & Domain.com.au and when customers attend an open for inspection.
            </p>
          </div>
          <div className="logo-section mt-5">
              <img className="img-fluid" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/realestate.png" alt="realestate.com.au" />
              <img className="ml-4 img-fluid" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/domain.png" alt="domain" />
          </div>
        </div>
      </div>
      <div className="digital-tools">
          <div className="container">
              <div className="row">
                <div className="col-12">
                    <h3 class="h3-careers mb-5">All our digital tools</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/automate.png" alt="Automate" />
                        </div>
                        <div className="text-side">
                            <h3>
                              Are totally automated, nothing for Agents to do.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/information.png" alt="information" />
                        </div>
                        <div className="text-side">
                            <h3>
                              Majority of enquiries come after hours, automated tools help deliver instant information for customers when you're not available​.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/conversation.png" alt="conversation" />
                        </div>
                        <div className="text-side">
                            <h3>
                            Allows your follow up calls to have a much deeper conversation, as customers are well versed in the property.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/relationships.png" alt="relationships" />
                        </div>
                        <div className="text-side">
                            <h3>
                            Doesn’t interfere with your important existing client relationships
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/opportunity.png" alt="opportunity" />
                        </div>
                        <div className="text-side">
                            <h3>
                            Provides important new listing appraisal opportunities​
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/free.png" alt="free" />
                        </div>
                        <div className="text-side">
                            <h3>
                            Are free for Agents / Offices who have an Oxygen referral agreement​.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="tool-card">
                        <div className="icon-side">
                          <img className="" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/self-service.png" alt="self-service" />
                        </div>
                        <div className="text-side">
                            <h3>
                            Allow customers to self service on important property features like rental appraisals, building & pest reports, comparable sales and many other brochures (its your listing – you decide what is shown) ​
                            </h3>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div className="crm-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <h2 className="section-title">
                    We fully integrate with the following CRMs
                </h2>

                <div className="logo-section mt-5">
                    <img className="img-fluid" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/agentbox.png" alt="Agent Box" />
                    <img className="ml-4 img-fluid" src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/rex.png" alt="Rex" />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cta-banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <h2>Like to know more?</h2>
                        <p>Use the Contact us form or send us an email: <a href="mailto:oxygen@oxygen.com.au" className="email-click">oxygen@oxygen.com.au</a></p>
                    </div>
                    <div className="col-lg-4 col-xl-3 ml-lg-auto mt-4 mt-lg-0 d-lg-flex flex-column align-items-stretch justify-content-center">
                        <Link to="/contact" className="default-btn primary-btn btn-lg">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
      <Footer />
    </Layout>
  )
}

export default Services
